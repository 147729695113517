import React from 'react'
import PropTypes from 'prop-types'
import VideoPlaylist from './VideoPlaylist'
import VideoGrid from './VideoGrid'
import Summary from '../../primitive/summary/component/Summary'
import platforms from '../../../../../common/video/platforms'
import classNames from 'classnames'

class Video extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedVideo: props.videos[0],
    }

    this.updateVideoDetails = this.updateVideoDetails.bind(this)
  }

  updateVideoDetails(video, scrollToVideo) {
    this.setState({
      selectedVideo: video,
      autoPlay: true,
    })
    const scrollOffset = 150
    const heroVideoScrollTop =
      this.videoPlaylistWrapper.offsetTop - scrollOffset
    document.body.scrollTop = heroVideoScrollTop
    document.documentElement.scrollTop = heroVideoScrollTop
  }

  render() {
    const {
      isPlaylist,
      provider,
      showDetails,
      layout = 'Carousel',
      width,
      position,
      name,
    } = this.props
    const platform = platforms[provider]
    const iFrame = (
      <iframe
        src={platform.embedUrl(
          this.state.selectedVideo.videoId,
          this.state.autoPlay
        )}
        loading="lazy"
        width="500"
        height="281"
        frameBorder="0"
        webkitallowfullscreen="webkitallowfullscreen"
        mozallowfullscreen="mozallowfullscreen"
        allowFullScreen="allowfullscreen"
        referrerpolicy="strict-origin-when-cross-origin"
        title={this.state.selectedVideo.title}
      />
    )
    const thumbnailProps = {
      ...this.props,
      onThumbnailClick: this.updateVideoDetails,
    }

    if (showDetails) {
      return (
        <div className="content-gutter content-gutter--reverse-tablet">
          <div className="grid">
            <div
              className="grid__item tablet-landscape-one-half"
              ref={(videoPlaylistWrapper) => {
                this.videoPlaylistWrapper = videoPlaylistWrapper
              }}
            >
              <figure
                className={classNames('inline-video inline-video--featured', {
                  'inline-video--square': name === 'Facebook Video',
                })}
              >
                {iFrame}
              </figure>
            </div>
            <div className="grid__item tablet-landscape-one-half">
              <div
                className={classNames(
                  'inline-video-description',
                  name === 'Facebook Video'
                    ? 'inline-video-description--square'
                    : 'inline-video-description--rectangle'
                )}
              >
                <div className="inline-video-description__inner">
                  <Summary
                    tag="SaintsTV"
                    modifiers={['featured-video']}
                    title={this.state.selectedVideo.title}
                    text={this.state.selectedVideo.description}
                    meta={this.state.selectedVideo.date}
                  />
                </div>
              </div>
            </div>
          </div>
          {isPlaylist && layout === 'Carousel' && (
            <VideoPlaylist {...thumbnailProps} />
          )}
          {isPlaylist && layout === 'Grid' && <VideoGrid {...thumbnailProps} />}
        </div>
      )
    } else {
      return (
        <div className="content-gutter content-gutter--reverse-tablet">
          <figure
            className={classNames('inline-video', {
              [`inline-video--${width}`]: width,
              [`inline-video--${position}`]: position,
            })}
          >
            {iFrame}
          </figure>
          {isPlaylist && layout === 'Carousel' && (
            <VideoPlaylist {...thumbnailProps} />
          )}
          {isPlaylist && layout === 'Grid' && <VideoGrid {...thumbnailProps} />}
        </div>
      )
    }
  }
}

Video.propTypes = {
  header: PropTypes.string,
  backgroundShape: PropTypes.bool,
  videos: PropTypes.array,
  isPlaylist: PropTypes.bool,
  src: PropTypes.string,
  provider: PropTypes.string,
  position: PropTypes.string,
  width: PropTypes.string,
  layout: PropTypes.string,
  showDetails: PropTypes.bool,
  name: PropTypes.string,
}

export default Video
